import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {CompanyApi} from "../../../services/api.service";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import AuthService from "../../../services/auth.service";
import {withRouter} from "react-router-dom";
import {DropzoneArea} from "material-ui-dropzone";
import {END_POINT} from "../../../constant";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import PageSubtitleComponent from "../../ui/page-subtitle/page-subtitle.component";
import TextField from "@material-ui/core/TextField";
import {Select} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";

const Company = new CompanyApi();
const CompanySchema = yup.object().shape({
    com_attorney: yup.string().required('campo obbligatorio'),
    com_email: yup.string().email('Inserisci un\'email valida').required('campo obbligatorio'),
    com_name: yup.string().required('campo obbligatorio'),
    com_phone: yup.string(),
    com_website: yup.string().url('Inserisci un url valido'),
});

const useStyles = makeStyles((theme) => ({
    mySelect: {
        width: '100%',
    },
}));

const AccountComponent = ({dispatch, history, mode, company}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState();
    const [companies, setCompanies] = useState([]);
    const [importFromCompany, setImportFromCompany] = useState(false);
    const companyForm = useForm({
        mode: "onSubmit",
        validationSchema: CompanySchema,
        defaultValues: company
    });
    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                setLoading(true);
                const companies = await Company.query();
                AuthService.setCompanies(companies);
                setCompanies(companies);
            } catch (message) {
                await AuthService.logout();
                history.push("/login");
                dispatch(handleToast({ message }));
            }
            setLoading(false);
        };
        fetchCompanies().then(r => {console.log(r)});
    }, []);
    const inputs = [
        {
            name: 'com_name',
            required: 'campo obbligatorio',
            label: 'Ragione sociale',
            placeholder: 'Inserisci ragione sociale',
            width: 4,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_attorney',
            required: 'campo obbligatorio',
            label: 'Rappresentanza legale',
            placeholder: 'Inserisci rappresentanza legale',
            width: 4,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_email',
            required: 'campo obbligatorio',
            label: 'Email',
            placeholder: 'Inserisci email',
            width: 4,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_website',
            label: 'Website',
            placeholder: 'https://....',
            width: 6,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_phone',
            label: 'Telefono',
            placeholder: 'Inserisci numero di telefono',
            width: 6,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_reply_to',
            label: 'Default Reply-To',
            placeholder: 'Inserisci email',
            width: 6,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
        {
            name: 'com_flow_email',
            label: 'Email Notifica Chiusura Flusso',
            placeholder: 'Inserisci email',
            width: 6,
            margin: 'normal',
            disabled: mode !== 'create' && mode !== 'update'
        },
    ];
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} >{label}</Typography>
    };
    const saveCompany = async (data) => {
        setLoading(true);
        if (logo) {
            data.com_logo = logo;
        }
        if (importFromCompany) {
            if ( !window.confirm("Attenzione, le informazioni verranno sovrascritte con quelle dell'azienda selezionata, continuare?") ) {
                setImportFromCompany(false);
                setLoading(false);
                return;
            }
            data.company_import = importFromCompany;
        }
        switch (mode) {
            case 'create':
                try {
                    await Company.create({
                        ...data
                    });
                    const companies = await Company.query();
                    AuthService.setCompanies(companies);
                    if ( importFromCompany ) {
                        dispatch(handleToast({
                            message: 'Account Creato - La clonazione potrebbe richiedere qualche minuto'
                        }));
                    } else {
                        dispatch(handleToast({
                            message: 'Account creato'
                        }));
                    }

                    history.push('/');
                    window.location.reload();
                } catch (message) {
                    dispatch(handleToast({message}));
                }
                break;
            case 'update':
                try {
                    await Company.update({
                        uuid: company.uuid,
                        ...data
                    });
                    const companies = await Company.query();
                    AuthService.setCompanies(companies);
                    if ( importFromCompany ) {
                        dispatch(handleToast({
                            message: 'Account Salvato - La clonazione potrebbe richiedere qualche minuto'
                        }));
                    } else {
                        dispatch(handleToast({
                            message: 'Account Salvato'
                        }));
                    }
                } catch (message) {
                    dispatch(handleToast({message}));
                }
                break;
            case 'delete':
                try {
                    await Company.delete(company.uuid);
                    const {companies} = await Company.query();
                    AuthService.setCompanies(companies);
                    dispatch(handleToast({
                        message: 'Account eliminato'
                    }));
                    history.push('/');
                } catch (message) {
                    dispatch(handleToast({message}));
                }
                break;
            default:
                return dispatch(handleToast({
                    message: 'Invalid saving mode'
                }));
        }
        setLoading(false);
        dispatch(fetchAPI());
        dispatch(handleModal({
            open: false
        }));
    };
    const fileUpload = (pics) => {
        setLogo(pics[0]);
    };
    const handleSelectChange = (event) => {
        let selCom = event.target.value;
        if (selCom === '') {
            selCom = false;
        }
        setImportFromCompany(selCom);
    };
    return (
        <form onSubmit={companyForm.handleSubmit(saveCompany)}>
            <FormContext {...companyForm}>
                <Grid container justify={"center"} spacing={4}>
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={3}>
                                {company && company.com_logo ? (
                                    <Avatar
                                        style={{
                                            margin: 10,
                                            width: 120,
                                            height: 120,
                                            border: '1px solid #ccc'
                                        }}
                                        src={`${company.com_logo}`}
                                    />
                                ) : (
                                    <Avatar
                                        style={{
                                            margin: 10,
                                            width: 120,
                                            height: 120,
                                            border: '1px solid #ccc'
                                        }}>
                                        <Icon style={{
                                            fontSize: '4rem'
                                        }}>apartment</Icon>
                                    </Avatar>
                                )}
                            </Grid>
                            <Grid item xs={9}>
                                {mode !== 'delete' && (
                                    <>
                                        <Typography variant={"h6"} align={"center"}>Logo Aziendale</Typography>
                                        <DropzoneArea
                                            onChange={fileUpload}
                                            filesLimit={1} acceptedFiles={['image/*']}
                                            dropzoneText={'Trascina l\'immagine o clicca qui'}
                                            dropzoneClass={!logo ? 'custom-dropzone' : 'custom-dropzone-no-text'}
                                            showAlerts={false} showFileNames={true}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={inputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction={"column"} spacing={2}>
                            <Grid item  >
                                <h4>Clona da un'azienda esistente</h4>
                                <small>Attenzione, la clonazione sovrascriverà i dati esistenti con quelli dell'azienda di partenza</small><br/>
                                <small>Non viene clonato tutto ma informazioni parziali</small>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} key={"seleziona-azienda"}>
                                    <Select className={classes.mySelect} defaultValue={""} onChange={handleSelectChange}>
                                    <MenuItem aria-label="None" key={"no-value"} value="" />
                                        {companies && companies.length > 0 && companies.map((company, index) => (
                                            <MenuItem value={company.id} key={"import-" + company.id}>{company.com_name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item key="final-button" xs={12} md={4} lg={2}>
                        <Button variant={"contained"} size={"small"}
                             color={'primary'}
                             disabled={loading} type={"submit"} style={{width: '100%'}}>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </FormContext>
        </form>
    )
};

AccountComponent.defaultProps = {
    company: {}
};
AccountComponent.propTypes = {
    company: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(withRouter(AccountComponent));
